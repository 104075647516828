.container {
  display: inline-flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
    color: var(--gray60);

    .box {
      border: none;
      background: var(--gray10);
      cursor: not-allowed;

      &::after {
        background: var(--gray60);
      }
    }
  }

  &:hover:not(.disabled) {
    .box {
      border: 1px solid var(--brand-primary);

      &.errored {
        border-color: var(--critical100);
      }
    }
  }
}

.input {
  display: none;
}

.box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.2s;
  position: relative;
  border: 1px solid var(--gray30);
  cursor: pointer;
  box-sizing: border-box;

  &.disabled {
    cursor: not-allowed;
  }

  &.checked {
    border-color: var(--brand-primary);

    &::after {
      opacity: 1;
    }
  }

  &.errored {
    border-color: var(--critical60);
    background: var(--critical10);
  }

  &::after {
    content: '';
    opacity: 0;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    border-radius: 50%;
    background: var(--brand-primary);
  }
}

.label {
  margin-left: 8px;
}
