.container {
  padding: 32px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  margin-right: 12px;
  font-size: 20px;
  line-height: 30px;
}

.closeBtn {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: 0.2s;

  &:hover {
    opacity: 0.6;
  }
}

.closeIcon {
  width: 32px;
  height: 32px;
}
