.container {
  width: 100%;
  max-width: 494px;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.subTitle {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.notice {
  margin-bottom: 16px;
}

.linksBlock {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.link {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: underline;
  color: var(--gray100);
  transition: 0.2s;

  &:hover {
    color: var(--gray160);
    text-decoration: underline;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.btn {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
