@import "src/assets/styles/mixines";

.container {
  padding: 16px 10px 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

  @include --tablet-s {
    padding: 16px 12px 0;
  }

  @include --desktop-s {
    padding: 16px 0 0;
  }
}

.content {
  background: var(--brand-white);
  border: 1px solid var(--gray10);
  border-radius: 24px;
  position: relative;
  max-width: 252px;
  width: 252px;
  padding: 28px;

  &.highlighted {
    border: 2px solid var(--accent100);
  }
}

.highlight {
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, -50%, 0);
  background: var(--gradient-blue-btn);
  border-radius: 8px;
  color: var(--brand-white);
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
  font-weight: 700;
  text-transform: uppercase;
}

.price {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  font-family: 'ReallySansLarge', sans-serif;
}

.priceMain {
  display: flex;
  font-weight: 950;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.5px;
}

.priceLabel {
  margin-bottom: 4px;
  font-weight: 950;
  font-size: 18px;
  line-height: 30px;

  @include --phone-m {
    font-size: 20px;
  }
}

.name {
  display: block;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gray30);
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.5px;

  @include --tablet {
    font-size: 20px;
    letter-spacing: -1.2px;
  }

  @include --desktop-s {
    font-size: 20px;
    letter-spacing: -1.2px;
  }

  .highlighted {
    color: var(--accent100);
  }
}

.info {
  margin-bottom: 20px;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 28px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  &.infoBold {
    font-weight: 700;
  }
}

.features {
  margin-bottom: 24px;
}

.featureItem {
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  * {
    color: inherit;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.featureIconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  // margin-top: 2px;
}

.btn {
  width: 100%;
}
