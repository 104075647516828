.form {

}

.formBlock {
  display: flex;
  margin-bottom: 10px;
}

.formLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 24px;
  height: 100%;
  min-height: 100%;
}

.formNumber {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  background: var(--gray10);
}

.formLine {
  flex: 1;
  width: 1px;
  background: var(--gray10);
}

.formRight {
  flex: 1;
  padding: 3px 0 3px;
}

.targetGroup {
  margin-bottom: 8px;
}

.groupInnerBlock {
  width: 100%;
}

.infoIconWrapper {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bindIpBlock {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.radioWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.timeIntervalSelect {
  width: 110px;
}

.inputGroupBottomNotice {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px
}

.optionFlag {
  width: 22px;
  height: 16px;
}

.deepTargetBlock {
  display: none;

  &.active {
    padding-top: 8px;
    display: block;
  }
}

.deepTargetSelect {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
