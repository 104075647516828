.container {
  width: 420px;
}


.header, .tr {
  display: flex;
}

.tr {

}

.th, .td {
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  &:first-child {
    width: 48px;
  }

  &:nth-child(2) {
    width: 160px;
  }

  &:nth-child(3) {
    flex: 1;
  }

  &:last-child {
    padding-right: 16px;

    button:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.th {
  background: var(--gray07);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px; /* 120% */
  letter-spacing: 0.1px;
  text-transform: uppercase;
}

.td {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
