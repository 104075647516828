.container {
  position: relative;
  width: 100%;
}

.content {
  cursor: pointer;
  padding: 5px 54px 5px 14px;
  border: 1px solid var(--slate-200);
  background: var(--slate-100);
  border-radius: 8px;
  transition: 0.2s;

  &:hover {
    border-color: var(--gray100);
  }
}

.tip {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  background: var(--slate-100);
  z-index: 10;
  opacity: 0;
  transition: 0.2s;
  cursor: pointer;

  &.active {
    opacity: 1;
  }
}

.iconWrapper {
  position: absolute;
  top: 7px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  z-index: 5;
}
