@mixin --phone-m {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin --tablet-xs {
  @media (min-width: 414px) {
    @content;
  }
}

@mixin --tablet-s {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin --tablet {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin --tablet-l {
  @media (min-width: 1244px) {
    @content;
  }
}

@mixin --desktop-s {
  @media (min-width: 1366px) {
    @content;
  }
}

@mixin --desktop-sa {
  @media (min-width: 1394px) {
    @content;
  }
}

@mixin --desktop {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin --desktop-m {
  @media (min-width: 1686px) {
    @content;
  }
}

@mixin --desktop-l {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
