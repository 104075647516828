.container {
  padding: 16px 28px;
  border-radius: 16px;
  border: 1px solid var(--gray10);
  background: var(--brand-white);
}

.title {
  margin-bottom: 8px;
  color: var(--gray60);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
