.container {
  position: relative;
  display: flex;

}

.actionButton {

}

.arrowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  width: 20px;
  height: 20px;
  margin-left: 8px;

  &.active {
    transform: rotate(180deg);
  }
}

.dropdown {
  max-height: 0;
  width: 200px;
  overflow-y: hidden;
  transition: 0.2s;
  position: absolute;
  bottom: -4px;
  right: 0;
  transform: translateY(100%);
  background: var(--brand-white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;

  &.opened {
    max-height: 1000px;
  }
}

.dropdownContent {
  padding: 8px 0;
  display: flex;
  flex-direction: column;
}

.option {
  display: flex;
  align-items: flex-start;
  background: var(--brand-white);
  border: none;
  padding: 9px 16px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: var(--brand-primary);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: var(--brand-wildSand);
  }

  &.active {
    background: var(--gray05);
  }
}

.optionIconWrapper {
  margin-right: 8px;
  display: flex;

  svg {
    width: 20px;
    height: 20px;
  }
}
