.container {

}

.title {
  margin-bottom: 32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.content {
  max-width: 494px;
}

.form {
  margin-bottom: 16px;
}

.formRow {
  display: grid;
  // grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.inputGroup {
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .input {
    width: 100%;
  }
}

.pricingBlock {
  margin-bottom: 16px;
}

.builder {

}

.builderHeader {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-radius: 6px 6px 0 0;
  background: var(--gray07);
}

.builderContent {
  padding: 20px;
  border-radius: 0 0 8px 8px;
  background: var(--gray160);
  color: var(--blue);

  span {
    color: var(--critical100);
  }
}
