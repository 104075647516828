.container {

}

.title {
  margin-bottom: 32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.content {
  max-width: 494px;
}

.inputGroup {
  margin-bottom: 32px;
}

.keyInput {
  flex: 1;
  margin-right: 8px;
}

.copy {
  margin-right: 8px;
}

.docs {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  a {
    color: var(--accent120);
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      color: var(--accent100);
    }
  }
}

.docsList {

}

.docsListItem {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.checkIconWrapper {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
