@import 'assets/styles/variables';

@font-face {
  font-family: 'ReallySansLarge';
  src: url('../assets/fonts/ReallySansLarge-Extra-Black.otf');
  src: url('../assets/fonts/ReallySansLarge-Extra-Black.otf') format('opentype');
  font-weight: 950;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Bold.otf');
  src: url('../assets/fonts/Inter-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Medium.otf');
  src: url('../assets/fonts/Inter-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter-Regular.otf');
  src: url('../assets/fonts/Inter-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: var(--brand-primary);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // color: var(--brand-primary);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

li {
  list-style: none;
}

b {
  font-weight: normal;
}

button {
  font-family: inherit;
  outline: none;

  &:focus {
    outline: none;
  }
}

label {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

#root {
  height: 100%;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.react-tooltip {
  max-width: 400px;
  z-index: 1;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 100ms;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
