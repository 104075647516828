.row {
  width: 100%;
  max-width: 736px;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: 1fr 1fr;
}

.planInfo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.planName {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.planStatus {
  margin-left: 8px;
}

.balance {
  display: flex;
  align-items: center;
}

.chartWrapper {
  width: 96px;
  height: 96px;
  margin-right: 16px;
  position: relative;
}

.chartCenterText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.balanceInfo {
  margin-bottom: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.modalTabs {
  margin-bottom: 20px;
}

.modalNotice {
  margin-bottom: 20px;
}

.modalFormRow {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.oneTimeInputWrapper {
  width: 120px;
  margin: 0 8px;
}

.inputGroup {
  margin-bottom: 20px;
}

.modalBottom {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: var(--gray100);
  text-align: center;

  a {
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      color: var(--gray120);
    }
  }
}
