:root {
  /** COLORS */
  --brand-accent: #33FCB1;
  --brand-onAccent: #03DD8D;
  --brand-primary: #22252F;
  // --brand-onPrimary: #3C4253;
  --brand-onPrimary: #334155;
  --brand-wildSand: #F4F4F4;
  --brand-white: #ffffff;
  --text-black: #243746;
  --popup-bg: rgba(60, 66, 83, 0.5);

  --accent160: #3B44A1;
  --accent140: #3C49AA;
  --accent120: #4060D0;
  --accent100: #4577F7;
  --accent60: #8FADFA;
  --accent30: #DAE4FD;
  --accent304: #EBF0FC;
  --accent306: #E5ECFC;
  --accent308: #E0E8FD;
  --accent10: #F6F8FF;
  --accent5: #F6F8FB;

  --gray160: #252525;
  --gray140: #323234;
  --gray120: #5A5B5E;
  --gray100: #808185;
  --gray60: #A1A2A5;
  --gray30: #D5D6D7;
  --gray20: #D0D4D9;
  --gray10: #E0E0E1;
  --gray07: #EEEEF3;
  --gray05: #F0F0F0;
  --gray01: #FCFCFC;

  --opacity40: rgba(255, 255, 255, 0.6);
  --opacity120: rgba(90, 91, 94, 0.3);

  --positive120: #008E7D;
  --positive100: #19B28D;
  --positive60: #52C5A9;
  --positive30: #BFE9DF;
  --positive10: #E8F7F4;

  --critical120: #C74952;
  --critical100: #EE585A;
  --critical60: #F27C83;
  --critical30: #FACED1;
  --critical10: #FDEEEE;

  --warning120: #D95A16;
  --warning100: #F88545;
  --warning60: #FAA373;
  --warning30: #FDDAC7;
  --warning10: #FEF3EC;

  --emerald700: #047857;
  --emerald200: #A7F3D0;
  --emerald50: #ECFDF5;

  --link-color: #0B5DCD;

  --blue: #50A6D7;

  --slate-200: #E2E8F0;
  --slate-100: #F1F5F9;

  /** SHADOWS */
  --shadow-m: 0px 2px 12px 0px rgba(34, 37, 47, 0.04);
  --shadow-l: 0px 4px 20px rgba(0, 0, 0, 0.08);
  --shadow-xl: 0px 8px 32px rgba(0, 0, 0, 0.08);

  /** INDENTS **/
  --default-margin: 32px;
  --mobile-header-height: 64px;
  --collapsed-nav-sidebar-width: 100px;
  --default-page-padding: 20px;

  /** GRADIENTS **/
  --gradient-black-btn: linear-gradient(278deg, #22252F -5.71%, #72757E 106.42%);
  --gradient-blue-btn: linear-gradient(278deg, #4B57D9 -5.71%, #6999F9 106.42%);
}
