.status {
  height: 32px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;

  &.active {
    background: var(--emerald200);
    color: var(--emerald700);
  }
}
