.container {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  &.copyable {
    cursor: pointer;
  }
}

.input {
  flex: 1;
  max-width: 100%;
  border: 1px solid var(--gray10);
  background: var(--brand-white);
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--brand-primary);
  transition: 0.2s;
  outline: none;
  font-family: 'Inter', sans-serif;

  &.withIcon {
    padding-right: 42px;
  }

  &::placeholder {
    color: var(--gray60);
  }

  &:read-only {
    background: var(--gray05);
  }

  &:hover,
  &:focus {
    border-color: var(--gray30);
    outline: none;
  }

  &.errored {
    border-color: var(--critical100);

    &:hover,
    &:focus {
      box-shadow: 0 0 0 1px var(--critical100);
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder {
    color: var(--gray60);
    font-size: inherit;
  }

  &.innerNoticed {
    padding-right: 182px;
  }
}

.input_m {
  height: 40px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 22px;
}

.input_l {
  height: 50px;
  padding: 0 24px;
}

.iconWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--brand-primary);
}

.innerNotice {
  width: 150px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: var(--gray100);
}

.copyTip {
  position: absolute;
  right: 1px;
  top: 1px;
  height: 38px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gray05);
  border-radius: 8px;
  opacity: 0;
  transition: 0.2s;

  &.active {
    opacity: 1;
  }
}
