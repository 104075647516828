.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.formWrapper {
  margin-bottom: 24px;
  width: 100%;
  max-width: 494px;
}

.inputGroup {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.accordionText {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px
}
