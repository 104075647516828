.container {
  display: flex;
  align-items: center;
  margin: var(--default-margin) 0;
}

.item {
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: var(--gray100);
  }

  &.activeItem {
    color: var(--gray100);
    cursor: initial;
  }
}

.itemLabel {
  color: inherit;
}

.arrowWrapper {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 16px;
}
