.header {
  padding: 5px;
  background: var(--gray07);
  border-radius: 6px 6px 0 0;
}

.content {
  border: 1px solid var(--gray07);
  background: #fff;
  border-top: none;
  border-radius: 0 0 6px 6px;
  padding: 32px;
}
