.container {
  width: 100%;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  &.margined {
    margin-right: 8px;
  }
}

.error,
.warning {
  position: absolute;
  left: 0;
  bottom: -4px;
  transform: translateY(100%);
}

.error {
  color: var(--critical100);
}

.warning {
  color: var(--warning100);
}
