.accordion {
  .accordionToggle {


    &.active .toggleIconWrapper {
      transform: rotate(180deg);
    }
  }
}

.accordionToggle {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  border: none;
  outline: none;
  background: transparent;
  border-bottom: 1px dashed var(--gray60);
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: var(--accent120);
  }
}

.toggleIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  transition: 0.1s;
}

.accordionContent {
  max-height: 0;
  transition: 0.3s;
  overflow: hidden;

  &.active {
    padding-top: 12px;
    max-height: 1000px;
  }
}
