.header {
  display: flex;
  height: 28px;
  min-height: 80px;
  margin-bottom: 32px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray30);
}

.itemsContainer {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.item {
  display: flex;
  align-items: center;
  color: var(--brand-primary);
  background: transparent;
  border: none;
  outline: none;
  height: 30px;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 40px;
  }

  &:hover {
    color: var(--brand-onPrimary);
  }
}

.iconWrapper {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  transition: 0.2s;
}

.itemLabel {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  transition: 0.2s;
}
