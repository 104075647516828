.container {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--gray10);
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.flag {
  width: 22px;
  height: 16px;
  margin-right: 4px;
}

.location {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.controls {
  margin-left: auto;

  button:not(:last-child) {
    margin-right: 16px;
  }
}

.info {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}
