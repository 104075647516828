@import "src/assets/styles/mixines";

.btn {
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  justify-content: center;
  border: none;
  outline: none;

  &.largeRadius {
    border-radius: 16px;
  }

  &.disabled {
    background: var(--gray10);
    border-color: var(--gray10);
    color: var(--gray60);
    cursor: unset;
  }

  &.loading {
    .text,
    .iconWrapper {
      opacity: 0;
    }
  }

  &.ghost {
    padding: 0;
    height: unset;
    width: unset;
    border: none;
    background: transparent;
    color: var(--gray60);
    border-radius: 0;

    &:hover {
      color: var(--accent120);
    }

    &.disabled {
      color: var(--gray30);
      background: transparent;
    }
  }

  &.wide {
    width: 100%;
  }
}

.s {
  height: 32px;
  padding: 0 12px;
}

.m {
  height: 40px;
  padding: 0 16px;
}

.l {
  height: 52px;
  padding: 0 16px;

  .text {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
}

.blue {
  background: var(--accent120);
  color: var(--brand-white);

  &:hover:not(&.disabled) {
    background: var(--accent100);
  }
}

.red {
  background: var(--critical120);
  color: var(--brand-white);

  &:hover:not(&.disabled) {
    background: var(--critical100);
  }
}

.lightGray {
  background: var(--gray100);
  color: var(--brand-white);

  &:hover:not(&.disabled) {
    background: var(--gray120);
  }
}

.outline {
  border: 1px solid var(--brand-onPrimary);
  background: var(--brand-white);

  &:hover {
    border-color: var(--gray30);
  }

  &:focus {
    // border-color: var(--gray30);
    outline: none;
  }

  &.disabled {
    color: var(--gray60);
    border-color: var(--gray10);
  }
}

.blueOutline {
  border: 1px solid var(--accent120);
  background: var(--brand-white);
  color: var(--accent120);

  &:hover:not(&.disabled) {
    background-color: var(--accent30);
  }

  &:focus {
    border-color: var(--accent100);
    outline: none;
  }

  &.disabled {
    color: var(--gray60);
    border-color: var(--gray10);
  }
}

.text {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: inherit;
  white-space: nowrap;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;

  &.margined {
    margin-right: 8px;
  }
}

.spinnerWrapper {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  svg {
    width: 20px;
    height: 20px;
  }
}
