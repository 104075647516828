.block {
  margin-bottom: 24px;
}

.productsTitle {
  margin-bottom: 16px;
}

.row {
  display: flex;
}

.productCard {
  &:not(:last-child) {
    margin-right: 16px;
  }
}
