.wrapper {
  padding-top: 56px;
  max-width: 1080px;
  margin: 0 auto;
}

.title {
  text-align: center;
  margin-bottom: 16px;
  color: var(--text-black);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.productsList {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
}

.productItem {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;

  &:not(:last-child) {
    margin-right: 32px;
  }
}

.productItemIconWrapper {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
