.container {
  width: 100%;
  max-width: 494px;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.description {
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.inputGroup {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.keyInput {
  margin-right: 8px;
}

.copy {
  margin-right: 8px;
}
