.container {
  //width: 100%;
  //max-width: 494px;
}

.title {
  margin-bottom: 16px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.block {
  margin-bottom: 32px;
}

.controls {
  margin-top: 16px;

  button:not(:last-child) {
    margin-right: 16px;
  }
}
