.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.plansList {
  margin-bottom: 32px;
}

.promoBlock {
  margin-bottom: 52px;
}
